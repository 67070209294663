import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTheme, useMediaQuery, Tabs, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography } from '@mui/material';

function App() {
  const [data, setData] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios('/api/');
        setData(result.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTable = (tableData) => {
    if (!tableData || tableData.length === 0) {
      return <Typography variant="subtitle1">No data available</Typography>;
    }

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {Object.keys(tableData[0]).map((key) => (
                <TableCell key={key} align="center">{key}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, rowIndex) => {
              const isExecuted = Object.values(row).some(value =>
                String(value).includes('Executed')); // Checking for 'Executed' in any value

              return (
                <TableRow
                  key={rowIndex}
                  sx={{
                    backgroundColor: rowIndex % 2 ? 'rgba(0, 0, 0, 0.04)' : 'inherit',
                  }}
                >
                  {Object.keys(row).map((key, cellIndex) => {
                    const value = row[key];
                    let formattedValue = value;
                    if ((key.includes('Ratio') || key.includes('PnL%')) && typeof value === 'number') {
                      formattedValue = `${(value * 100).toFixed(2)}%`;
                    } else if (typeof value === 'number') {
                      formattedValue = value.toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 });
                    }

                    return (
                      <TableCell
                        key={cellIndex}
                        align="center"
                        sx={{
                          fontWeight: isExecuted ? 'bold' : 'normal', // Apply fontWeight to TableCell
                        }}
                      >
                        {formattedValue}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={{ textAlign: 'center', padding: 2 }}>
        <Typography variant="h6">
          Bitcoin Price: {data?.btc_price?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) ?? 'Loading...'}
        </Typography>
        <Typography variant="h6">
          Last Update: {data?.last_update_time ?? 'Loading...'}
        </Typography>
      </Box>
      <Tabs
        value={selectedTab}
        onChange={handleChangeTab}
        aria-label="simple tabs example"
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          width: '100%',
          '.MuiTab-root': {
            // minWidth: isMobile ? 50 : { xs: '50%', sm: '25%', md: '12.5%' },
            minWidth: '33.3%',
            padding: isMobile ? theme.spacing(0.5, 1) : theme.spacing(1, 2), // 모바일에서 탭의 패딩을 줄입니다
            fontSize: isMobile ? '0.7rem' : '1rem', // 모바일에서 탭의 글자 크기를 줄입니다
          }
        }}
      >
        <Tab label="Sum" />
        {/*<Tab label="Ocas" />*/}
        <Tab label="Grid" />
        {/*<Tab label="Fire(자기자본)" />*/}
        <Tab label="Fire" />
        {/*<Tab label="FireUni" />*/}
        {/*<Tab label="Nudge(자기자본)" />*/}
        {/*<Tab label="Firebtc" />*/}
        {/*<Tab label="Bear" />*/}

      </Tabs>

      {selectedTab === 0 && (data ? renderTable(data.df_acc) : <Typography>Loading...</Typography>)}
      {selectedTab === 1 && (data ? renderTable(data.df_pasta_pos) : <Typography>Loading...</Typography>)}
      {selectedTab === 2 && (data ? renderTable(data.df_fire2_pos) : <Typography>Loading...</Typography>)}

      {/*{selectedTab === 4 && (data ? renderTable(data.df_fire3_pos) : <Typography>Loading...</Typography>)}*/}
      {/*{selectedTab === 5 && (data ? renderTable(data.df_okx_pos) : <Typography>Loading...</Typography>)}*/}
      {/*{selectedTab === 1 && (data ? renderTable(data.df_ocas_pos) : <Typography>Loading...</Typography>)}*/}
      {/*{selectedTab === 3 && (data ? renderTable(data.df_fire1_pos) : <Typography>Loading...</Typography>)}*/}
      {/*{selectedTab === 6 && (data ? renderTable(data.df_db_pos) : <Typography>Loading...</Typography>)}*/}
      {/*{selectedTab === 5 && (data ? renderTable(data.df_db_sub1_pos) : <Typography>Loading...</Typography>)}*/}
    </Box>
  );
}

export default App;